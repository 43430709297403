// export const baseUrl="https://localhost:7029/api/";
export const baseUrl="http://test.apexsoftware.click/api/";



export const usersApi = {
    getAllUsers: 'user',
}

export const chatbotData = [
    {
      pillar: "Issue Log",
      questionGroups: [
        {
          group: "Issue Logging",
          questions: [
            {
              question: "How do I log an issue?",
              placeholder: "",
              answer: "To log an issue, go to the Issue Log functionality and click the Add button. Fields to capture will appear, and then you can click the save button."
            },
            {
              question: "What is the purpose of an Issue Log?",
              placeholder: "",
              answer: "The purpose of an Issue Log is for you, the user, to report any difficulties that you may encounter within the system."
            },
            {
              question: "What happens after I log an issue?",
              placeholder: "",
              answer: "You will receive direct feedback confirming that your issue has been successfully logged, and your Admin/Leader will attend to it."
            },
            {
              question: "How can I determine if my logged issue has been resolved?",
              placeholder: "",
              answer: "You can check the status in your Issue Log table; it will indicate whether your logged issue is still pending or has been resolved."
            }
          ]
        }
      ]
    },
    {
      pillar: "Audit Trail",
      questionGroups: [
        {
          group: "Audit Trails",
          questions: [
            {
              question: "What is the purpose of an Audit Trail?",
              placeholder: "",
              answer: "The purpose of an Audit Trail is to record every interaction made by the user within the system."
            }
          ]
        }
      ]
    },
    {
      pillar: "Login Tracker",
      questionGroups: [
        {
          group: "Login Tracker",
          questions: [
            {
              question: "What is the purpose of a Login Tracker?",
              placeholder: "",
              answer: "The purpose of a Login Tracker is to keep records of users who have logged into the system. It also keeps records of login error attempts, whether successful or not."
            }
          ]
        }
      ]
    },
    {
      pillar: "User Access Control (UAC)",
      questionGroups: [
        {
          group: "UAC",
          questions: [
            {
              question: "What is UAC?",
              placeholder: "",
              answer: "UAC stands for User Access Control, a functionality whereby an Admin/Leader controls the permissions of a user within the system platform."
            },
            {
              question: "What happens when permissions within the system are limited?",
              placeholder: "",
              answer: "If certain permissions are disabled for you within the platform, you will only be able to access what has been granted to you."
            },
            {
              question: "Where can one generate permissions for a user within the system?",
              placeholder: "",
              answer: "Simply go to the UAC functionality and access permission settings for that specific user."
            },
            {
              question: "Can I change a user's role within the system?",
              placeholder: "",
              answer: "Absolutely! Simply go to the UAC functionality, search for the user you want to change the role for, and make the necessary changes."
            }
          ]
        }
      ]
    },
    {
      pillar: "Users",
      questionGroups: [
        {
          group: "Users",
          questions: [
            {
              question: "Where can I update a user's profile information?",
              placeholder: "",
              answer: "You can update user profiles by clicking on the Users functionality. A list of users within the system will be displayed, and you can update them as required, or you can perform bulk uploads."
            }
          ]
        }
      ]
    },
    {
      pillar: "Email Template",
      questionGroups: [
        {
          group: "Email",
          questions: [
            {
              question: "How can I preview how the email will display to the users?",
              placeholder: "",
              answer: "You can preview your email before saving it by clicking the preview button."
            },
            {
              question: "What is the purpose of the Email Template?",
              placeholder: "",
              answer: "The purpose of the Email Template is to manage the messages sent out to the users."
            }
          ]
        }
      ]
    },
    {
      pillar: "Input Fields",
      questionGroups: [
        {
          group: "Input Fields",
          questions: [
            {
              question: "What is an input field?",
              placeholder: "",
              answer: "An input field is where you manage access to certain fields."
            },
            {
              question: "What happens when no access is granted to input fields?",
              placeholder: "",
              answer: "Fields become disabled and non-capturable."
            }
          ]
        }
      ]
    },
    {
      pillar: "Help & Support",
      questionGroups: [
        {
          group: "Help & Support",
          questions: [
            {
              question: "Where can I find more information about the system?",
              placeholder: "",
              answer: "The Help and Support functionality will provide you with more in-depth information, and you can also download help guides."
            },
            {
              question: "How does Help & Support assist me?",
              placeholder: "",
              answer: "Firstly, Help & Support provides you with more clarity about the system. When you click on the prepopulated questions, you will receive an answer feedback below the question."
            }
          ]
        }
      ]
    }
  ];
  