import axios from 'axios';
import React, {useEffect, useState} from 'react';

import { useIsAuthenticated } from "@azure/msal-react"
import {PageLayout} from "../components/SSO/PageLayout";
import { loginRequest } from '../authConfig';
import { callMsGraph } from '../graph';
import { ProfileData } from '../components/SSO/ProfileData';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';



/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                callMsGraph(response.accessToken).then((response) => setGraphData(response));
            });
    }

    useEffect(()=>{
        RequestProfileData()
    }, [])

    return (
        <>
            <br/>
            {graphData && (
                <ProfileData graphData={graphData} />
            ) }
        </>
    );
};

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState();

    const [show, setShow] = useState(false);
    const [otpForm, setOtpForm] = useState(false);
    const [password, setPassword] = useState("")

    const login = (formData) => {
        axios.post(`login`, formData).
            then(res => {
                if (res.data && res.data.token) {
                    localStorage.setItem("token", res.data.token);
                    window.location.reload(true);
                }else{
                    setOtpForm(true)
                }
            }).catch(err => {
                setShow(true)
            })
    }

    const verifyOtp = (formData) => {
        axios.post(`verifyOtp`, formData).
            then(res => {
                localStorage.setItem("token", res.data.token);
                window.location.reload(true);
            }).catch(err => {
                setShow(true)
                window.location.reload(true);
            })
    }

    const submit = (e) => {
        e.preventDefault();

        if (otpForm) {
            verifyOtp({
                email: email,
                password: password,
                otp: otp
            });
        } else {
            login({
                email: email,
                password: password,
                otp: otp
            });
        }
    }

    const goBackToSignIn = () => {
        setOtpForm(false)
    }

    /**
     * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
     */
    const MainContent = () => {
        return (
            <div className="App">
                <AuthenticatedTemplate>
                    <ProfileContent />
                </AuthenticatedTemplate>

            </div>
        );
    };

    return (
        <>
            <section class="h-100 gradient-form" >
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-xl-10">
                            <div class="card rounded-3 text-black">
                                <div class="row g-0">
                                    <div class="col-lg-6">
                                        <div class="card-body p-md-5">

                                            <div class="text-center">
                                                <img src="assets/images/logos/clogo.png" alt="" width="260" height="60" />


                                            </div>
                                            {!otpForm ? (
                                                <>
                                                    <div className=" pb-0 text-center">
                                                        <p className="h-1 my-3">Enter your email and password to sign in</p>
                                                    </div>
                                                    <div className="card-body">
                                                        <form role="form" onSubmit={submit}>
                                                            <div className="mb-3">
                                                                <label className="form-check-label" htmlFor="rememberMe">Email</label>

                                                                <input type="email" className="form-control form-control-lg" placeholder="Email" aria-label="Email" onChange={(e) => setEmail(e.target.value)} />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="form-check-label" htmlFor="rememberMe">Password</label>

                                                                <input type="password" className="form-control form-control-lg" placeholder="Password" aria-label="Password" onChange={(e) => setPassword(e.target.value)} />
                                                            </div>
                                                            <div className="form-check form-switch d-flex">
                                                                <input className="form-check-input" type="checkbox" id="rememberMe" />
                                                                <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                                            </div>
                                                            <div className="text-center">
                                                                <button type="submit" className="btn btn-lg bg-primary btn-lg w-100 mt-4 mb-0 text-light font-weight-600">Sign in</button>
                                                            </div>



                                                            <PageLayout>
                                                                <MainContent />
                                                            </PageLayout>
                                                        </form>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="card-body">
                                                        <form role="form" onSubmit={submit}>
                                                            <div className="mb-3">
                                                                <input type="text" className="form-control form-control-lg" value={otp} placeholder="Enter OTP" aria-label="Email" onChange={(e) => setOtp(e.target.value)} />
                                                            </div>


                                                            <div className="text-center d-flex">
                                                                <button onClick={() => goBackToSignIn()} type="button" className="btn btn-sm bg-danger btn-sm w-100 mt-4 mb-0 text-light font-weight-600 me-3">Cancel</button>

                                                                <button type="submit" className="btn btn-sm bg-primary btn-sm w-100 mt-4 mb-0 text-light font-weight-600 ms-3">Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex align-items-center bg-primary rounded-2">
                                        <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                                            <h4 class="mb-4">We are more than just a company</h4>
                                            <p class="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SignIn;
