import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package
 */

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (event, loginType) => {
        event.preventDefault()
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch((e) => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
            });
        }
    };
    return (
        <>
            <div className="text-center">
                <button onClick={(e) => handleLogin(e,"popup")} className="btn btn-lg bg-secondary btn-lg w-100 mt-4 mb-0 text-light font-weight-600">
                    <i className="fa-brands fa-windows"></i> Sign in with Microsoft AD
                </button>
            </div>
        </>

    );
};