import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { useNavigate, useParams } from 'react-router-dom';

const IssueLogForm = () => {
  const [id, setId] = useState(useParams().id)
  const [issue, setIssue] = useState(null);

  const [data, setData] = useState(
    [
      {
        id: 1,
        issueCategory: "Performance",
        subject: "Update employee info",
        loggedBy: "",
        dateOpened: "2023-08-22 11:28:43",
        dateClosed: "",
        issue: "Performance should be Fully Achieved.",
        status: "Not Started",
        comment:""

      },

      {
        id: 2,
        issueCategory: "Termination",
        subject: "Remove employee - resigned",
        loggedBy: "Lerato Bopape",
        dateOpened: "2023-08-21 17:14:48",
        dateClosed: "2023-08-21 17:16:15",
        status: "Closed",
        issue: "We can remove employee 12345 as he has resigned.",
        comment:"Noted, and actioned."

      }
    ]
  )
  const navigate = useNavigate();
  const [systems, setSystems] = useState([
    {
      label: "Employee Recognition",
    },
    {
      label: "Employee Wellness",
    },
    {
      label: "Issue Logging resolution",
    },
    {
      label: "Engagement Surveys",
    },

    {
      label: "Recruitment Onboarding",
    },
    {
      label: "New Hire Surveys",
    },
    {
      label: "Exit Interviews",

    },
    {
      label: "LMS",

    },
    {
      label: "Work Architecture & Job Benchmarking",
    },
    {
      label: "WSP / ATR Reporting",
    },
    {
      label: "Performance Management",
    },
    {
      label: "Talent Management",
    },
    {
      label: "Increase & Bonus Allocation",
    },
    {
      label: "Incentive Scheme Contracting",
    },
  ])

  const [categoryId, setCategoryId] = useState(null)
  const [subCategoryId, setSubCategoryId] = useState(null)
  const [lookUps, setLookUps] = useState({
    "milestones": ["", "M1", "M2", "M3", "M4", "M5"],
    "races": ["", "African", "Colored", "Indian", "White", "Black"],
    "genders": ["", "Male", "Female"],
    'bands': ["", "B1", "B2", "B3", "B4", "B5"],
    "performances": ["", "1 - Not Achieved", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved", "TEA -  Too early to assess"]
  })

  const [categories, setCategories] = useState(
    [
      {
        id: 1,
        name: "Update Employee Information",
        subCategories: [
          {
            id: 1,
            name: "Personal Information",
            fields: [
              {
                name: "Personnel Number",
                type: "text"
              },
              {
                name: "Last Name",
                type: "text"
              },
              {
                name: "Known As",
                type: "text"
              },
              {
                name: "Email Address",
                type: "email"
              },
              {
                name: "Start Date",
                type: "date"
              },
              {
                name: "Race",
                lookup: "races",
                type: "select"
              },
              {
                name: "Gender",
                lookup: "genders",
                type: "select"
              }
            ]
          },

          {
            id: 2,
            name: "Performance",
            fields: [
              {
                name: "Personnel Number",
                type: "text"
              },
              {
                name: "Current Performance",
                lookup: "performances",
                type: "select"
              },
              {
                name: "Prior Performance",
                lookup: "performances",
                type: "select"
              }
            ]
          }

          ,

          {
            id: 3,
            name: "Salary",
            fields: [
              {
                name: "Personnel Number",
                type: "text"
              },
              {
                name: "Current (Annual) Salary",
                type: "number"
              },
              {
                name: "Proposed Increase %",
                type: "number"
              },
              {
                name: "Prior Incentive",
                type: "number"
              },
              {
                name: "Proposed Incentive %",
                type: "number"
              },


            ]
          },


          {
            id: 4,
            name: "Position",
            fields: [
              {
                name: "Personnel Number",
                type: "text"
              },
              {
                name: "Position",
                lookup: "performances",
                type: "text"
              },
              {
                name: "Band",
                lookup: "bands",
                type: "select"
              },
              {
                name: "Milestone",
                lookup: "milestones",
                type: "select"
              }
            ]
          },


          {
            id: 5,
            name: "Reporting Line",
            fields: [
              {
                name: "Personnel Number",
                type: "text"
              },
              {
                name: "Line Manager",
                type: "text"
              },
              {
                name: "Allocation Manager 1",
                type: "text"
              },
              {
                name: "Allocation Manager 2",
                type: "text"
              },
            ]
          },


          {
            id: 6,
            name: "Organisational Structure",
            fields: [
              {
                name: "Personnel Number",
                type: "text"
              },
              {
                name: "Business Area",
                type: "text"
              },
              {
                name: "Business Unit",
                type: "text"
              },
              {
                name: "Division",
                type: "text"
              },
              {
                name: "Department",
                type: "text"
              },
            ]
          }
        ]
      },

      {
        id: 2,
        name: "Termination",
        subCategories: [
          {
            id: 1,
            name: "Termination",
            fields: [
              {
                name: "Personnel Number",
                type: "text"
              },
              {
                name: "Last Name",
                type: "text"
              },
              {
                name: "Known As",
                type: "text"
              },
            ]
          }
        ]
      },


      {
        id: 3,
        name: "Other",
        subCategories: [
          {
            id: 1,
            name: "Other",
            fields: [
              {
                name: "Personnel Number",
                type: "text"
              },
              {
                name: "Last Name",
                type: "text"
              },
              {
                name: "Known As",
                type: "text"
              },
              {
                name: "Comment",
                type: "textarea"
              },
            ]
          }
        ]
      },


    ]
  );
  useEffect(() => {
    if (id) {
      setIssue(data.find((item, index) => item.id == id))
      if (id == 2) {
        setCategoryId(id)
        setSubCategoryId(1)
      } else if (id == 1) {
        setSubCategoryId(2)
        setCategoryId(id)

      }
    }
  }, [])

  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"/Issue Logs"} />
      <main id="main" className="main">
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                  <h6 className='fs-4'>ISSUE LOG DETAILS</h6>
                  <form>

                    <div class="form-group mb-3">
                      <label for="exampleFormControlSelect2">System</label>
                      <select class="form-select" id="exampleFormControlSelect2" defaultValue={id} disabled={id}>
                        <option value={null}>---Select Option--- </option>

                        {
                          systems.map((item, key) => (
                            <>
                              <option value={key}>{item.label}</option>
                            </>
                          ))
                        }
                      </select>
                    </div>
                    <div class="form-group mb-3">
                      <label for="exampleFormControlInput1">Subject</label>
                      <input type="text" class="form-control" placeholder="Enter Subject" disabled={id} defaultValue={issue?.subject} />
                    </div>

                    <div class="form-group mb-3">
                      <label for="exampleFormControlSelect2">Category</label>
                      <select onChange={(e) => { setCategoryId(e.target.value) }} value={id ? id : categoryId} class="form-select" id="exampleFormControlSelect2" disabled={id}>
                        <option value={null}>---Select Option--- </option>

                        {
                          categories.map((item, key) => (
                            <>
                              <option value={item.id}>{item.name}</option>
                            </>
                          ))
                        }
                      </select>
                    </div>

                    <div class="form-group mb-3">
                      <label for="exampleFormControlSelect2">Sub Catergory</label>
                      <select class="form-select" id="exampleFormControlSelect2" onChange={(e) => { setSubCategoryId(e.target.value) }} disabled={id} value={id == 1 ? 2 : id == 2 ? 1 : subCategoryId}>
                        <option value={null}>---Select Option--- </option>
                        {
                          categories.find(item => item.id == categoryId) && categories.find(item => item.id == categoryId).subCategories.map((item, key) => (
                            <>
                              <option value={item.id}>{item.name}</option>
                            </>
                          ))
                        }
                      </select>
                    </div>

                    <div class="form-group mb-3">
                      <label for="exampleFormControlTextarea1">Issue</label>
                      <textarea defaultValue={issue?.issue} disabled={id} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                    </div>
                    <div class="form-group">
                      <div class="mb-3">
                        <label for="formFileSm" class="form-label">Attachement</label>
                        <input disabled={id} class="form-control form-control-sm" id="formFileSm" type="file" />
                      </div>
                    </div>

                    <br />
                    <hr />
                    <br />

                    <div class="form-group">
                      <label className="text-capitalize" for="exampleFormControlTextarea1">EXTRA FIELDS</label>
                    </div>

                    {
                      !id && categories.find(item => item.id == categoryId) && categories.find(item => item.id == categoryId).subCategories.map((item, key) => (
                        <>
                          {
                            item.id == subCategoryId && item.fields.map((field, index) => (
                              <>
                                {
                                  field.type == "select" && field.lookup ? (
                                    <>
                                      <div class="form-group mb-3">
                                        <label for="exampleFormControlSelect2">{field.name}</label>
                                        <select  disabled={id} class="form-select" id="exampleFormControlSelect2">

                                          {
                                            lookUps[field.lookup] && lookUps[field.lookup].map((item, key) => (
                                              <>
                                                <option value={key}>{item}</option>
                                              </>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </>
                                  )
                                    : field.type == "textarea" ? (<>
                                      <div class="form-group">
                                        <label for="exampleFormControlInput1">{field.name}</label>
                                        <textarea disabled={id} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                      </div>
                                    </>)
                                      : (
                                        <>
                                          <div class="form-group mb-3">
                                            <label for="exampleFormControlInput1">{field.name}</label>
                                            <input  disabled={id} type={field.type} class="form-control" />
                                          </div>
                                        </>
                                      )
                                }

                              </>
                            ))
                          }
                        </>
                      ))
                    }

                    {
                      id && categories.find(item => item.id == categoryId) && categories.find(item => item.id == categoryId).subCategories.map((item, key) => (
                        <>
                          {
                            item.id == subCategoryId && item.fields.map((field, index) => (
                              <>
                                {
                                  field.type == "select" && field.lookup ? (
                                    <>
                                      <div class="form-group mb-3">
                                        <label for="exampleFormControlSelect2">{field.name}</label>
                                        <select defaultValue={3} disabled={id} class="form-select" id="exampleFormControlSelect2">

                                          {
                                            lookUps[field.lookup] && lookUps[field.lookup].map((item, key) => (
                                              <>
                                                <option value={key}>{item}</option>
                                              </>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </>
                                  )
                                    : field.type == "textarea" ? (<>
                                      <div class="form-group">
                                        <label for="exampleFormControlInput1">{field.name}</label>
                                        <textarea disabled={id} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                      </div>
                                    </>)
                                      : (
                                        <>
                                          <div class="form-group mb-3">
                                            <label for="exampleFormControlInput1">{field.name}</label>
                                            <input defaultValue={field.name === "Personnel Number" ? "12345" : field.name === "Last Name" ? "Snow" : field.name === "Known As" ? "White" : ""} disabled={id} type={field.type} class="form-control" />
                                          </div>
                                        </>
                                      )
                                }

                              </>
                            ))
                          }
                        </>
                      ))
                    }


                   {!id && (
                    <>
                     <div className='d-flex'>
                      <button type="button" class="btn btn-primary btn-sm my-1">Save</button>
                      <button onClick={() => { navigate("/issue-logs") }} type="button" class="btn btn-danger btn-sm my-1 ms-auto">Cancel</button>
                    </div>
                    </>
                   )}

                    {id && (
                      <>
                        <br />
                        <hr />
                        <br />

                        <div class="form-group mb-3">
                          <label for="exampleFormControlTextarea1">Comment</label>
                          <textarea defaultValue={issue?.comment}  class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>

                        <div className='d-flex'>
                          <button onClick={() => { navigate("/issue-logs") }} type="button" class="btn btn-primary btn-sm my-1  me-2">Mark as In Progress</button>
                          <button onClick={() => { navigate("/issue-logs") }} type="button" class="btn btn-primary btn-sm my-1">Mark as Resolved</button>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default IssueLogForm;
