import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const Engage = () => {



  return (
    <>
        <Header icon={"fa-solid fa-users fa-xl mx-2"}  label={"People Management/Engage"} />
      <main id="main" className="main">

        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>

                  <div className='card-description px-3'>
                    <p>
                      Engaging employees helps foster a collective commitment that encourages active participation in various aspects of our organisation, influencing the extent to which employees feel connected and dedicated to our mission.
                    </p>
                  </div>
                  <div className='content-block bg-light-grey rounded-2 p-3'>

                      <div className="d-flex align-items-center mb-3">
                      <span className="icon-shape icon shadow rounded-2 bg-secondary me-2 h-1">
                       <i className="fa-solid fa-user-tie text-white"></i>
                      </span>
                          <div className='card-content-headers fw-bold'>
                              Recruitment & Onboarding
                          </div>
                      </div>

                    <p>Talent acquisition is the process of efficiently finding, attracting, and hiring the most qualified individuals in a shorter time frame and with reduced risks. Inefficient recruitment processes may lead the best candidates to seek opportunities elsewhere.</p>
                    <p>
                    Our Recruitment & Onboarding system help streamline our recruitment process in order to provide a credible, proactive and value-adding service to the business; and to ensure an exceptional recruitment experience for applicants, employees and business units alike.
                    </p>
                      <hr />
                    <div className='link-container'>
                      <a href=''>
                      Click here to access the Recruitment & Onboarding system
                      </a>
                    </div>
                  </div>

                  <div className='content-block bg-light-grey rounded-2 p-3'>

                      <div className="d-flex align-items-center mb-3">
                          <span className="icon-shape icon shadow rounded-2 bg-secondary me-2 h-1">
                           <i className="fa-solid fa-id-card-clip text-white"></i>
                          </span>
                          <div className='card-content-headers fw-bold'>
                              New Hire Surveys
                          </div>
                      </div>

                      <p>The primary objective of the New Hires Surveys system is to leverage an automated survey solution to collect valuable data that can enhance processes, increase the sense of value among new hires, and ultimately improve retention and satisfaction levels.</p>
                    <p>We understand that surveys not only gauge the company's progress in making candidates feel welcome but also contribute to its
enhancement.</p>
                      <hr />
                    <div className='link-container'>
                      <a href='https://demo-new-hire.concisesolutions.co.za/' target="_blank">
                      Click here to access the New Hires Surveys system
                      </a>
                    </div>
                  </div>

                  <div className='content-block bg-light-grey rounded-2 p-3'>
                      <div className="d-flex align-items-center mb-3">
                          <span className="icon-shape icon shadow rounded-2 bg-secondary me-2 h-1">
                           <i className="fa-solid fa-user-pen text-white"></i>
                          </span>
                          <div className='card-content-headers fw-bold'>
                              Exit Interviews
                          </div>
                      </div>

                    <p>
                    Exit interviews play a crucial role as the final step in the employee life cycle. They provide valuable insights into the employer and offer recommendations for improvements on various aspects, including the working environment, culture, processes and systems, management style, and development opportunities
                    </p>
                      <hr />
                    <div className='link-container'>
                      <a href='https://demo-exit.concisesolutions.co.za/' target={'_blank'}>
                      Click here to access the Exit Interviews system
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default Engage;
