import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';

const Dashboard = () => {
  
  return (
    <>
      <Header label={<h5 className='py-0 my-0 text-dark font-style-italic'>Your HR World in One Place!</h5>} />
      <main id="main" className="main">
        <div className='row mb-5'>
          <div className='col-lg-12'>
            <div className='homepage-banner rounded'>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='h-4 card-content-headers mb-2'>
                    Welcome to Workwise Portal, your central hub for all things related to Human Resources.
                  </div>

                  <div className='card-description mb-5'>
                    <p>
                      Here, you'll discover a consolidated resource for HR information, links, and systems. Our Employee Central portal streamlines access to HR systems, offering a convenient "one-stop" solution. If you need to access a specific link, simply click on the corresponding name below.
                    </p>
                  </div>
                  <img className='fluid-image rounded' src='assets/images/homepage-image-1.png' />

                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-header bg-secondary text-light'>
                Latest News / Communication
              </div>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='d-flex'>
                    <div className='h-4 card-content-headers mb-2'>
                      Deadline: Performance Management.
                    </div>
                    <div className='ms-auto'>
                      12 Sep 23
                    </div>
                  </div>

                  <div className=''>
                    <p>
                      You are reminded that the current Performance Management cycle will close on 29th September 2023. All outstanding assessments are to be completed before or on this date. For any support, please contact your HR Business Partner in this regard.
                    </p>
                  </div>
                </div>
                <br />
                <hr />
                <br />

                <div className='content-block mt-2'>
                  <div className='h-4 card-content-headers mb-2'>
                  </div>
                  <div className='d-flex'>
                    <div className='h-4 card-content-headers mb-2'>
                      Progress Status: Performance Management.
                    </div>
                    <div className='ms-auto'>
                      23 Aug '23
                    </div>
                  </div>
                  <div className=''>
                    <p>
                      A reminder that the current Performance cycle is closing in one month - a snapshot of progress for your area below.
                    </p>
                  </div>
                  <div className='row d-flex'>
                    <div className='col-lg-7 mx-auto mt-4'>
                      <img className='fluid-image rounded ' src='assets/images/homepage-charts.png' />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </main>

    </>
  )
}
export default Dashboard;
