import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const DocumentResources = () => {
    const data = [
        {
            question: 'Q: What is Workwise Portal?',
            answer: '<p>The Workwise Portal is your central hub for all things related to Human Resources.</p> ' +
                '<p>Here, you\'ll discover a consolidated resource for HR information, links, and systems. Our Employee Central portal streamlines access to HR systems, offering a convenient "one-stop" solution. If you need to access a specific link, simply click on the corresponding name below.</p>',
        },
        {
            question: 'Q: Where can I ask questions directly from the platform?',
            answer: 'Anyone can ask questions directly on this platform via the chatbot. Once the admin responds to your question, a response will appear in your chat history in the chatbot.',
        },
        {
            question: 'Q: Who do I contact to assist should I have a query.',
            answer: 'Using the Issue Log functionality is best recommended to use for any queries. This functionality ensures that you are able to track your logged query until it is resolved. Alternatively, please contact the help desk via email at: queries@conciseflow.co.za',
        },
    ];

    const createMarkup = (htmlString) => {
        return { __html: htmlString };
    };

    return (
        <>
            <Header icon={"fa-solid fa-info fa-xl mx-2"} label={"Help and Support"} />
            <main id="main" className="main">
                <section className="section ">
                    <div className='faq-banner rounded'>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default DocumentResources;
