import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const Reward = () => {



  return (
    <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"}  label={"People Management/Reward"} />

      <main id="main" className="main">

        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>

                  <div className='card-description px-3'>
                    <p>
                    Rewarding employees with fair, competitive, and well-deserved incentives serves to foster a collective appreciation that encourages active dedication in various facets of our organisation, ultimately influencing the extent to which employees feel connected and committed
to our mission.
                    </p>
                  </div>

                  <div className='content-block bg-light-grey rounded-2 p-3'>
                      <div className="d-flex align-items-center mb-3">
                          <span className="icon-shape icon shadow rounded-2 bg-secondary me-2 h-1">
                           <i className="fa-solid fa-money-bill-trend-up text-white"></i>
                          </span>
                          <div className='card-content-headers fw-bold'>
                              REMSYS: Increase & Incentive Allocation
                          </div>
                      </div>
                    <p>Our organisation allocates annual increases and incentives to qualifying employees. An automated system, REMSYS, is used to enable a structured and proactive management of the Reward process, resulting in a more efficient Reward cycle and outcome</p>

                    <p>
                    The system aims at improving the quality of final performance-based salary increases and incentives. The REMSYS solution covers a structured Reward process encompassing validations, allocations, and reward letters.
                    </p>
                      <hr />
                    <div className='link-container'>
                      <a href='https://demo-remsys.concisesolutions.co.za/' target={'_blank'}>
                      Click here to access REMSYS
                      </a>
                    </div>
                  </div>

                  <div className='content-block bg-light-grey rounded-2 p-3'>

                      <div className="d-flex align-items-center mb-3">
                          <span className="icon-shape icon shadow rounded-2 bg-secondary me-2 h-1">
                           <i className="fa-solid fa-file-contract text-white"></i>
                          </span>
                          <div className='card-content-headers fw-bold'>
                              Incentive Scheme Contracting
                          </div>
                      </div>
                    <p>
                    As part of our overall Reward value proposition, we adopt an annual Incentive Scheme Contracting process for eligible employees. The Incentive Scheme programme aims to reward key employees whose performance is critical towards the achievement of business objectives.
                    </p>
                      <hr />
                    <div className='link-container'>
                      <a href=''>
                      Click here to access the Incentive Scheme Contracting system
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default Reward;
